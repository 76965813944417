import React, { useEffect, useState } from "react";
import "../styles/global.css";
import "../styles/ventanaFlotante.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import ConsultaForm from "../Forms/Consulta/ConsultaForm";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import medicGeekApi from "../config/axiosConfig";
import MedicineSVG from "./../assets/svg/undraw_medicine_b-1-ol.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import dayjs from "dayjs";

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

const MainContent = ({ setSelectedPaciente, setCurrentForm, currentForm }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { profileId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [formData, setFormData] = useState({});
  const [paciente, setPaciente] = useState([]);
  const [cita, setCita] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [nuevosPacientes, setNuevosPacientesRegistrados] = useState(0);
  const [
    porcentajePacienteConDenominador,
    setPorcentajePacienteConDenominador,
  ] = useState(0);
  const [porcentajeCitaConDenominador, setPorcentajeCitaConDenominador] =
    useState(0);

  const [nuevosCitas, setNuevosCitasRegistrados] = useState(0);
  const [citasMesActual, setCitasMesActual] = useState(0);

  const [nuevasConsultas, setNuevosConsultasRegistradas] = useState(0);
  const [porcentajeSemanal, setPorcentajeCambioMesConsultas] = useState(0);
  const [porcentajeCambioSemanaConsultas, setPorcentajeCambioSemanaConsultas] =
    useState(0);

  const [consultas, setConsultas] = useState([]);
  const [filtroMes, setFiltroMes] = useState(false);
  const consultasPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);

  // Chard 1
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [selectedYear, setSelectedYear] = useState(""); // Estado para el año seleccionado
  // chard 2
  const [polarAreaData, setPolarAreaData] = useState(null);
  const [polarAreaOptions, setPolarAreaOptions] = useState(null);

  const [calendarData, setCalendarData] = useState([]);
  const [citasHoy, setCitasHoy] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [diaSemana, setDiaSemana] = useState("");
  const [formattedDateTime, setFormattedDateTime] = useState("");
  const [today, setToday] = useState("");

  // Enviar paciente a consulta
  const [pacienteConsulta, setPacienteConsulta] = useState(null);
  const [nombrePacienteSeleccionado, setNombrePacienteSeleccionado] =
    useState("");

  const [selectedFormulario, setSelectedFormulario] = useState("");
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const [formulario, setFormulario] = useState({
    categoriaNm: "",
    horaFormateada: "",
    fechaCita: "",
    nombrePaciente: "",
    nombreDelPaciente: "",
    direccionPaciente: "",
    contactoPaciente: "",
    emailPaciente: "",
    notaCita: "",
    estatusCalendar: "",
    pacienteId: "",
  });

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedConsulta, setSelectedConsulta] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

// Modal Citas
const [showCitasModal, setShowCitasModal] = useState(false);
const [citas, setCitas] = useState([]);
const [searchDate, setSearchDate] = useState("");

// Paginación citas
const itemsPerPage = 10; // Número de elementos por página
const [currentPage, setCurrentPage] = useState(1);


  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // // const currentItems = citas.slice(indexOfFirstItem, indexOfLastItem);


  // Cache
  const [cache, setCache] = useState({
    patients: null,
    consults: null,
    calendars: null,
  });
  const [lastUpdate, setLastUpdate] = useState(null);
  

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
   
    const today = new Date();
  
    // Calcular la fecha del primer día del último mes
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setDate(1);
  
    // Calcular la fecha del primer día de la semana actual (lunes)
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - ((today.getDay() + 6) % 7)); // Asegura que el primer día sea un lunes
  
    // Calcular la fecha del primer día de la semana pasada (lunes)
    const lastWeek = new Date(firstDayOfWeek);
    lastWeek.setDate(lastWeek.getDate() - 7);
  
    const fetchData = async () => {
      setLoading(true); // Iniciar la carga
  
      if (!profileData || !hasLoaded) {
        try {
          let profileData = null;
    
          if (profileId) {
            // Si hay un profileId, se está editando un perfil existente
            const response = await medicGeekApi.get(`/profile/${profileId}`);
            profileData = response.data;
            setProfileData(profileData);
          } else {
            // Si no hay un profileId, se está creando un nuevo perfil
            const responseProfile = await medicGeekApi.get("/profile");
            const dataProfile = responseProfile.data;
    
            if (Array.isArray(dataProfile) && dataProfile.length > 0) {
              profileData = dataProfile[0];
              setProfileData(profileData);
            }
          }
    
          if (!hasLoaded) {
            // Aquí implementamos la lógica de caché
            const cachedData = JSON.parse(localStorage.getItem("dashboardData")); // Obtener datos del caché
            const cacheTime = localStorage.getItem("dashboardCacheTime");
            const now = new Date();
    
            // Si existe el caché y tiene menos de 1 hora (3600000 ms), lo usamos
            if (cachedData && cacheTime && now - new Date(cacheTime) < 3600000) {
              // Usamos los datos de caché
              setNuevosPacientesRegistrados(cachedData.nuevosPacientesRegistrados);
              setPorcentajePacienteConDenominador(cachedData.porcentajePacienteConDenominador);
              setNuevosConsultasRegistradas(cachedData.nuevosConsultasRegistrados);
              setPorcentajeCambioMesConsultas(cachedData.porcentajeCambioMesConsultas);
              setPorcentajeCambioSemanaConsultas(cachedData.porcentajeCambioSemanaConsultas);
              setConsultas(cachedData.consultas);
              setChartData(cachedData.chartData);
              setNuevosCitasRegistrados(cachedData.nuevosCitasRegistrados);
              setPorcentajeCitaConDenominador(cachedData.porcentajeCitaConDenominador);
              setCitasHoy(cachedData.citasHoy);
              setToday(cachedData.today);
              setHasLoaded(true);
            } else {
              // Si no hay caché o es antiguo, obtenemos los datos del endpoint
              // Usar Promise.all para hacer las solicitudes en paralelo
              const [responsePatients, responseConsults, responseCalendars] = await Promise.all([
                medicGeekApi.get("/patients"),
                medicGeekApi.get("/consults"),
                medicGeekApi.get("/calendars"),
              ]);
    
              const allPatients = responsePatients.data;
              const allConsults = responseConsults.data;
              const allCitas = responseCalendars.data;
    
              // Filtrar y calcular estadísticas
              const pacientesSemanaActual = allPatients.filter((p) => {
                const fechaRegistro = new Date(p.pcteFechaReg);
                return fechaRegistro >= firstDayOfWeek && fechaRegistro <= today;
              });
    
              const pacientesSemanaPasada = allPatients.filter((p) => {
                const fechaRegistro = new Date(p.pcteFechaReg);
                return fechaRegistro >= lastWeek && fechaRegistro < firstDayOfWeek;
              });
    
              const calculatePercentageChange = (total, subset) => {
                if (total === 0) return '0.00%'; // Evitar división por cero
                const change = ((subset - total) / total) * 100;
                return change > 0 ? `+${change.toFixed(2)}%` : `${change.toFixed(2)}%`;
              };
    
              const porcentajeCambioPacientes = calculatePercentageChange(
                pacientesSemanaPasada.length,
                pacientesSemanaActual.length
              );
    
              setNuevosPacientesRegistrados(allPatients.length);
              setPorcentajePacienteConDenominador(porcentajeCambioPacientes);
    
              // Consultas
              const sortedConsults = allConsults.map((consulta) => {
                const paciente = allPatients.find((p) => Number(p.pcteId) === Number(consulta.pcteId));
                return {
                  ...consulta,
                  pcteNom: paciente ? paciente.pcteNom : 'No encontrado',
                  pcteApe1: paciente ? paciente.pcteApe1 : 'No encontrado',
                  pcteApe2: paciente ? paciente.pcteApe2 : 'No encontrado',
                  pcteEd: paciente ? paciente.pcteEd : 'No encontrado',
                  pcteDir: paciente ? paciente.pcteDir : 'No encontrado',
                };
              }).sort((a, b) => b.consultId - a.consultId);

    
              const consultasMes = sortedConsults.filter((c) => {
                const fechaConsulta = new Date(c.consultFec);
                return fechaConsulta >= lastMonth && fechaConsulta <= today;
              });
    
              const consultasSemana = sortedConsults.filter((c) => {
                const fechaConsulta = new Date(c.consultFec);
                return fechaConsulta >= lastWeek && fechaConsulta <= today;
              });
    
              const porcentajeCambioConsultasMes = calculatePercentageChange(
                allConsults.length,
                consultasMes.length
              );
    
              const porcentajeCambioConsultasSemana = calculatePercentageChange(
                allConsults.length,
                consultasSemana.length
              );
    
              setNuevosConsultasRegistradas(allConsults.length);
              setPorcentajeCambioMesConsultas(porcentajeCambioConsultasMes);
              setPorcentajeCambioSemanaConsultas(porcentajeCambioConsultasSemana);
              setConsultas(sortedConsults);
    
              const chartData = generateChartData(sortedConsults);
              setChartData(chartData);
    
              // Citas
              const citasSemanaActual = allCitas.filter((cita) => {
                const fechaRegistro = new Date(cita.fechaCitaReg);
                return fechaRegistro >= firstDayOfWeek && fechaRegistro <= today;
              });
    
              const citasSemanaPasada = allCitas.filter((cita) => {
                const fechaRegistro = new Date(cita.fechaCitaReg);
                return fechaRegistro >= lastWeek && fechaRegistro < firstDayOfWeek;
              });
    
              const porcentajeCambioCitas = calculatePercentageChange(
                citasSemanaPasada.length,
                citasSemanaActual.length
              );
    
              setNuevosCitasRegistrados(allCitas.length);
              setPorcentajeCitaConDenominador(porcentajeCambioCitas);
    
              // Detalles de citas
              const calendarResponse = await medicGeekApi.get("/calendars/detail");
              const calendarData = calendarResponse.data;
    
              const formatDate = (dateTimeStr) => {
                if (typeof dateTimeStr !== "string") {
                  return { formattedDate: "", formattedHour: "" };
                }
    
                const [datePart, timePart, period] = dateTimeStr.split(" ");
                if (!datePart || !timePart || !period) return { formattedDate: "", formattedHour: "" };
    
                const [day, month, year] = datePart.split("/");
                const [hour, minute] = timePart.split(":");
    
                let formattedHour = parseInt(hour, 10);
                if (period === "PM") formattedHour += 12;
    
                const citaDate = new Date(year, month - 1, day, formattedHour, minute);
                const formattedDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    
                return {
                  formattedDate,
                  formattedHour: citaDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                };
              };
    
              const formattedToday = today.toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
    
              const citasHoy = calendarData.filter((paciente) => {
                const { formattedDate } = formatDate(paciente.fechaCita);
                return formattedDate === formattedToday;
              });
    
              setCitasHoy(citasHoy);
              setToday(formattedToday);
    
              // Guardar datos en el caché con una marca de tiempo
              const dashboardData = {
                nuevosPacientesRegistrados: allPatients.length,
                porcentajePacienteConDenominador: porcentajeCambioPacientes,
                nuevosConsultasRegistradas: allConsults.length,
                porcentajeCambioMesConsultas: porcentajeCambioConsultasMes,
                porcentajeCambioSemanaConsultas: porcentajeCambioConsultasSemana,
                consultas: sortedConsults,
                chartData: chartData,
                nuevosCitasRegistrados: allCitas.length,
                porcentajeCitaConDenominador: porcentajeCambioCitas,
                citasHoy: citasHoy,
                today: formattedToday,
              };
    
              // Guardar en localStorage
              localStorage.setItem("dashboardData", JSON.stringify(dashboardData));
              localStorage.setItem("dashboardCacheTime", new Date().toISOString());
    
              setHasLoaded(true);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Manejar el error aquí
        } finally {
          setLoading(false); // Finalizar carga
        }
      }
    };
  
    fetchData();
  }, [profileId,hasLoaded]);
  
  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Meses empiezan desde 0
    const dd = String(today.getDate()).padStart(2, "0");
    setSearchDate(`${yyyy}-${mm}-${dd}`); // Formato yyyy-MM-dd
  }, []);

  const handleShowModal = (consulta) => {
    setSelectedConsulta(consulta);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedConsulta(null);
  };

  // Chard 1
  const generateChartData = (sortedConsults) => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const consultasPorMes = {};
    sortedConsults.forEach((consulta) => {
      const parts = consulta.consultFec?.split("/"); // Suponiendo que la fecha está en formato dd/mm/yyyy
      const dia = parseInt(parts[0]);
      const mesNumerico = parseInt(parts[1]);
      const año = parseInt(parts[2]);

      if (isNaN(dia) || isNaN(mesNumerico) || isNaN(año)) {
        console.error(`Error en el formato de fecha: ${consulta.consultFec}`);
        return; // Salir de la iteración actual si la fecha no está en el formato correcto
      }

      const fechaConsulta = new Date(año, mesNumerico - 1, dia); // Crear objeto de fecha en formato yyyy-mm-dd
      const mes = monthNames[fechaConsulta.getMonth()];

      const fechaFormateada = `${dia.toString().padStart(2, "0")}/${mesNumerico
        .toString()
        .padStart(2, "0")}/${año}`;

      // console.log(fechaFormateada);

      if (consultasPorMes[mes]) {
        consultasPorMes[mes]++;
      } else {
        consultasPorMes[mes] = 1;
      }
    });

    const labels = Object.keys(consultasPorMes);
    const data = Object.values(consultasPorMes);

    const datasetConsults = {
      label: "Consultas por Mes",
      data,
      backgroundColor: ["rgb(63, 128, 234)"], // Color de fondo de las barras
      borderColor: ["rgb(17, 113, 239)"], // Color del borde de las barras
      borderWidth: 1, // Ancho del borde
      borderRadius: 8,
    };

    const chartOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      height: 500,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 800,
        },
      },
      animation: true, // Desactiva las animaciones
    };

    return {
      labels,
      datasets: [datasetConsults],
    };
  };
  // Termina el grafico

  const displayedConsults = consultas.slice(
    pageNumber * consultasPerPage,
    (pageNumber + 1) * consultasPerPage
  );

  citasHoy.sort((a, b) => {
    const horaA = new Date(a.fechaCita).getHours();
    const horaB = new Date(b.fechaCita).getHours();
    return horaA - horaB;
  });

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleEnviarConsultaClick = async (paciente) => {
    try {
      // console.log("Evento seleccionado:", paciente);

      // Accede al ID del paciente dentro del objeto paciente.patient
      const pacienteId = paciente.patient?.pcteId;
      const { patient, estatus, calendarId, category } = paciente;
      const { categoryId } = category;
      const consultaCita = { ...patient, estatus, calendarId, categoryId };

      // console.log(consultaCita)

      setSelectedPaciente(consultaCita);
      setCurrentForm("consultaForm");
      setSelected(true);

      // Redirige al usuario al URL correcto con el ID del paciente
      navigate(`/patients/${pacienteId}/encounters/0`);
    } catch (error) {
      console.error("Error al cargar la última consulta:", error);
    }
  };

  const SkeletonCard = () => (
    <div className="col-lg-6 col-xl-3">
      <div className="card-stats mb-4 mb-xl-0 card text-start">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">
              <h5 className="text-uppercase text-muted mb-0 card-title skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></h5>
              <span className="h5 font-weight-bold mb-0 skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "30px", width: "50px" }}></span>
            </div>
            <div className="col-auto col">
              <div
                className="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center skeleton-placeholder"
                style={{ backgroundColor: "#e0e0e0", width: "60px", height: "60px" }}
              >
              </div>
            </div>
          </div>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></span>
          </p>
        </div>
      </div>
    </div>
  );

  const SkeletonTableRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "50px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "200px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "100px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "150px" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "20px", width: "30px" }}></div>
      </td>
    </tr>
  );
  
  const SkeletonAppointmentRow = () => (
    <tr>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "50px", width: "100%" }}></div>
      </td>
      <td>
        <div className="skeleton-placeholder" style={{ backgroundColor: "#e0e0e0", height: "50px", width: "100%" }}></div>
      </td>
    </tr>
  );
  
  const SkeletonLoader = ({ width = '100%', height = '100%', borderRadius = '0.5rem' }) => {
    return (
      <div
        style={{
          width,
          height,
          backgroundColor: '#e0e0e0',
          borderRadius,
          animation: 'pulse 1.5s infinite',
        }}
      />
    );
  };

  // Función para manejar la apertura del modal con la consulta seleccionada
  const handleViewDetail = (consulta) => {
    setSelectedConsulta(consulta); // Establece la consulta seleccionada
    setModalOpen(true); // Abre el modal
  };

  // Función para cerrar el modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  //  citas
 // Filtrar citas por fecha
const filteredCitas = citas.filter((cita) => {
  const citaDate = cita.formattedDate.split(" ")[0]; // Extrae solo la fecha (dd/mm/yyyy)
  return citaDate === searchDate.split("-").reverse().join("/"); // Compara fecha seleccionada con formato invertido
});

// Calcular índices para la paginación
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;

// Obtener los elementos de la página actual
const currentItems = filteredCitas.slice(indexOfFirstItem, indexOfLastItem);

// Calcular número total de páginas
const totalPages = Math.ceil(filteredCitas.length / itemsPerPage);

// Manejar el cambio de página
const handlePageChange = (pageIndex) => {
  if (pageIndex >= 1 && pageIndex <= totalPages) {
    setCurrentPage(pageIndex);
  }
};

  const handleOpenCitasModal = async () => {
    const formatDate = (dateTimeStr) => {
      if (typeof dateTimeStr !== "string") {
        return { formattedDate: "", formattedHour: "", timestamp: 0 };
      }
  
      const [datePart, timePart, period] = dateTimeStr.split(" ");
      if (!datePart || !timePart || !period) return { formattedDate: "", formattedHour: "", timestamp: 0 };
  
      const [day, month, year] = datePart.split("/");
      let [hour, minute] = timePart.split(":");
  
      hour = parseInt(hour, 10);
      minute = parseInt(minute, 10);
  
      // Convertir hora AM/PM al formato 24 horas para usar en Date()
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }
  
      // Crear un objeto Date usando los valores procesados
      const dateObject = new Date(year, month - 1, day, hour, minute);
  
      // Formatear la fecha y hora para mostrar
      const formattedDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      const displayHour = dateObject.getHours() % 12 || 12; // Convertir a formato 12 horas
      const displayMinute = dateObject.getMinutes().toString().padStart(2, "0");
      const displayPeriod = dateObject.getHours() >= 12 ? "PM" : "AM";
      const formattedHour = `${displayHour}:${displayMinute} ${displayPeriod}`;
  
      // Crear un timestamp para ordenar las citas
      const timestamp = dateObject.getTime();
  
      return { formattedDate, formattedHour, timestamp };
    };
  
    try {
      const response = await medicGeekApi.get("/calendars/detail");
      const calendarData = response.data.map((paciente) => {
        const { formattedDate, formattedHour, timestamp } = formatDate(paciente.fechaCita);
        return {
          ...paciente,
          formattedDate,
          formattedHour,
          timestamp, // Guardar timestamp para ordenar
        };
      });
  
      // Ordenar las citas por el timestamp
      calendarData.sort((a, b) => a.timestamp - b.timestamp);
  
      setCitas(calendarData);
      setShowCitasModal(true);
    } catch (error) {
      console.error("Error al obtener citas:", error);
    }
  };
  
  const handleCloseCitasModal = () => {
    setShowCitasModal(false);
  };

  const handleSearchDate = (event) => {
    setSearchDate(event.target.value);
  };

  const handlePrintPDF = () => {
    const doc = new jsPDF();
    
    // Información estática para el PDF
    const header = "Listado de Citas Pendientes";
    const currentDate = dayjs().format("DD/MM/YYYY");
  
    // Encabezado del documento
    doc.setFontSize(18);
    doc.text(header, 14, 22);
  
    // Fecha actual
    doc.setFontSize(12);
    doc.text(`Fecha: ${currentDate}`, 14, 30);
  
    // Crear la tabla con las citas filtradas
    const citasData = filteredCitas.map((cita) => [
      // Manejar null para los datos del paciente
      [
        cita.patient?.pcteNom || "",
        cita.patient?.pcteNom2 || "",
        cita.patient?.pcteApe1 || "",
        cita.patient?.pcteApe2 || "",
      ]
        .filter((item) => item) // Filtra valores vacíos
        .join(" "), // Une los nombres con espacios
    
      // Manejar null para la fecha y hora
      `${cita.formattedDate || "Sin fecha"} ${cita.formattedHour || "Sin hora"}`,
    
      // Manejar null para la categoría
      cita.category?.categoryNm || "Sin categoría",
    ]);
    
  
    // Añadir la tabla al PDF
    doc.autoTable({
      startY: 40, // Posición inicial de la tabla
      head: [["Paciente", "Fecha", "Motivo"]], // Encabezados de la tabla
      body: citasData, // Datos de las citas
      theme: "grid",
      styles: { fontSize: 10 },
    });
  
    // Agregar número total de páginas
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        14,
        doc.internal.pageSize.height - 10,
        `Página ${i} de ${pageCount}`
      );
    }
  
    // Descargar el PDF
    doc.save("listado-citas.pdf");
  };

  return (
    
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div style={{ paddingBottom: "5rem" }}>
          <div
            className="miDivConGradiente h-100"
            style={{
              minHeight: "20rem",
            }}
          >
            <div className="p-2 mx-4 ">
              <div className="d-flex justify-content-start pt-2 pb-4">
              </div>
              <div className="row">
                  {loading ? (
                <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </>
              ) : (
                <>
                  <div class="col-lg-6 col-xl-3">
                    <div
                      class="card-stats mb-4 mb-xl-0 card text-start"
                      style={{
                        backgroundColor: "#E0EAFC",
                      }}
                    >
                      <div class="card-body">
                        <div class="row align-items-center" style={{
                            display: "flex",
                            flexWrap: "wrap"
                          }}>
                          <div class="col" >
                            <h5 class="mb-0" style={{ color: "#3F80EA" }}>
                              {" "}
                              Bienvenido de nuevo!
                            </h5>
                            {/* <span class="h5 font-weight-bold mb-0" style={{color:'#3F80EA'}}>{profileData.docNom}!</span> */}
                            <p class="mt-2 mb-0 text-muted text-sm">
                              <span class="mr-2">
                                <i class="fa fa-arrow-up"></i> MedicGeek Dashboard
                              </span>
                            </p>
                          </div>
                          <div
                            class="col"
                            style={{
                              width: "100px",
                              height: "80px",
                            }}
                          >
                            <img
                              src={MedicineSVG}
                              alt="Medicine"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-xl-3">
                    <div class="card-stats mb-4 mb-xl-0 card text-start">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h5 class="text-uppercase text-muted mb-0 card-title">
                              Pacientes
                            </h5>
                            <span
                              class="h5 font-weight-bold mb-0"
                              style={{ color: "green" }}
                            >
                              {nuevosPacientes.toLocaleString()}
                            </span>
                          </div>
                          <div class="col-auto col">
                            <div
                              class="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#fd0061",
                                width: "60px",
                                height: "60px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-user-plus"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#ffffff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M16 19h6" />
                                <path d="M19 16v6" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        
                        <p class="mt-3 mb-0 gap-1 text-muted text-sm" style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}>
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i>
                            {porcentajePacienteConDenominador.toLocaleString()}
                          </span>
                          <span class="text-nowrap"> Desde la semana pasada</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Card Pacientes */}

                  <div class="col-lg-6 col-xl-3">
                    <div class="card-stats mb-4 mb-xl-0 card text-start">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h5 class="text-uppercase text-muted mb-0 card-title">
                              {" "}
                              Consultas
                            </h5>
                            <span
                              class="h5 font-weight-bold mb-0"
                              style={{ color: "green" }}
                            >
                              {nuevasConsultas.toLocaleString()}
                            </span>
                          </div>
                          <div class="col-auto col">
                            <div
                              class="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#ff4500",
                                width: "60px",
                                height: "60px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-clipboard-data"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#ffffff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                <path d="M9 17v-4" />
                                <path d="M12 17v-1" />
                                <path d="M15 17v-2" />
                                <path d="M12 17v-1" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <p class="mt-3 mb-0 gap-1 text-muted text-sm" style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}>
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i>
                            {porcentajeCambioSemanaConsultas}
                          </span>
                          <span class="text-nowrap"> Desde la semana pasada</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Card Consultas */}

                  <div class="col-lg-6 col-xl-3">
                    <div class="card-stats mb-4 mb-xl-0 card text-start">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col">
                            <h5 class="text-uppercase text-muted mb-0 card-title">
                              {" "}
                              Citas
                            </h5>
                            <span
                              class="h5 font-weight-bold mb-0"
                              style={{ color: "green" }}
                            >
                              {nuevosCitas}
                            </span>
                          </div>
                          <div class="col-auto col">
                            <div
                              class="icon icon-shape text-white rounded-circle shadow d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#00abfb",
                                width: "60px",
                                height: "60px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-chart-arrows-vertical"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#ffffff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M18 21v-14" />
                                <path d="M9 15l3 -3l3 3" />
                                <path d="M15 10l3 -3l3 3" />
                                <path d="M3 21l18 0" />
                                <path d="M12 21l0 -9" />
                                <path d="M3 6l3 -3l3 3" />
                                <path d="M6 21v-18" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <p class="mt-3 mb-0 gap-1 text-muted text-sm" style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}>
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i>
                            {porcentajeCitaConDenominador}
                          </span>
                          <span class="text-nowrap"> Desde la semana pasada</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Card Citas */}
                </>
                )}
              </div>
            </div>
          </div>
          {/* Cards */}

          <div
            className="d-flex gap-3 justify-content-center"
            style={{
              height: "25rem",
              padding: "0 2rem",
            }}
          >
            <div
              className="bg-white rounded-2 contenedor-chart2 d-flex justify-content-start"
              style={{
                position: "relative",
                top: "-6rem",
                height: "30rem",
                width: "40%",
              }}
            >
              <div className="d-flex w-100 py-3 justify-content-around gap-4">
                <div className="fs-5 text-secondary">
                  <i className="bi bi-calendar-range px-2"></i>
                  <label>Citas Pendientes</label>
                </div>
                <div className="fs-5">
                  <button
                    className="px-4 bg-white text-success rounded-2 border border-success-subtle"
                    onClick={handleOpenCitasModal}
                  >
                    Listado
                  </button>
                </div>

              </div>
              
              <div className="text-end w-100 pe-3 pb-2">
                <label className="fw-bold text-decoration-underline">Leyenda</label>
                <div className="d-flex justify-content-end gap-2">
                  <div>
                    <label className="bg-success border-0 rounded px-1 text-white">Activa</label>
                  </div>
                  <div>
                    <label className="bg-primary border-0 rounded px-1 text-white">Completada</label>
                  </div>
                  <div>
                    <label className="bg-danger border-0 rounded px-1 text-white">Cancelada</label>
                  </div>
                </div>
              </div>
              <div
                className="text-start w-100 p-2"
                style={{ maxHeight: "100%", overflowY: "auto" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Información</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      // Mostrar 5 filas de skeleton mientras se carga
                      Array.from({ length: 5 }).map((_, index) => <SkeletonAppointmentRow key={index} />)
                    ) : (
                      citasHoy.map((paciente) => (
                        <tr
                          key={paciente.id}
                          className={
                            paciente.estatus === "Completada"
                              ? "table-secondary no-hover"
                              : paciente.estatus === "Cancelada"
                              ? "table-danger no-hover"
                              : ""
                          }
                          style={{
                            opacity:
                              paciente.estatus === "Cancelada" || paciente.estatus === "Completada"
                                ? 0.5
                                : 1,
                          }}
                        >
                          <td className="table-light" style={{ width: "15%" }}>
                            <div className="d-flex flex-column">
                              <div className="text-decoration-underline">
                                {paciente.patient.pcteNom} {paciente.patient.pcteNom2} {paciente.patient.pcteApe1} {paciente.patient.pcteApe2}
                              </div>
                              <div className="">{paciente.category.categoryNm}</div>
                              <div className="fw-bold">
                                {paciente.fechaCita.split(" ")[1]} {paciente.fechaCita.split(" ")[2]}
                              </div>
                            </div>
                          </td>
                          <td
                            className="text-end cursor-hover table-light"
                            style={{ width: "10%" }}
                          >
                            <div className="rounded text-center py-2 ">
                              <button
                                style={{ width: "12rem", height: "3rem" }}
                                type="button"
                                className={`text-center pr-1 m-0 border py-2 rounded fw-normal  ${
                                  paciente.estatus === "Completada"
                                    ? "bg-primary text-light"
                                    : paciente.estatus === "Cancelada"
                                    ? "bg-danger text-light"
                                    : "button-send bg-success text-white"
                                }`}
                                title={
                                  paciente.estatus === "Completada"
                                    ? "Cita Completada"
                                    : paciente.estatus === "Cancelada"
                                    ? "Cita Cancelada"
                                    : "Enviar a Consulta"
                                }
                                id="hoverIcon"
                                disabled={
                                  paciente.estatus === "Cancelada" ||
                                  paciente.estatus === "Completada"
                                }
                                onClick={() => handleEnviarConsultaClick(paciente)}
                              >
                                {paciente.estatus === "Completada" ? (
                                  <i className="bi bi-check-circle-fill text-opacity pe-2"></i>
                                ) : paciente.estatus === "Cancelada" ? (
                                  <i className="bi bi-x-circle-fill text-opacity pe-2"></i>
                                ) : (
                                  <i className="bi bi-arrow-right-circle-fill text-opacity pe-2"></i>
                                )}
                                {paciente.estatus === "Completada"
                                  ? "Cita Completada"
                                  : paciente.estatus === "Cancelada"
                                  ? "Cita Cancelada"
                                  : "Enviar a Consulta"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    {citasHoy.length === 0 && !loading && (
                      <tr>
                        <td colSpan="2">No hay citas disponibles para hoy</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal isOpen={showCitasModal} toggle={handleCloseCitasModal} size="xl">
              <ModalHeader toggle={handleCloseCitasModal}>Citas Agendadas</ModalHeader>
              <ModalBody>
                <div className="mb-3">
                  <label>Buscar por fecha:</label>
                  <input
                    type="date"
                    className="form-control"
                    value={searchDate}
                    onChange={handleSearchDate}
                  />
                </div>
                  <div className="text-start p-2" style={{ maxHeight: "100%", overflowY: "auto"}}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Paciente</th>
                          <th>Hora</th>
                          <th>Motivo</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((paciente) => (
                          <tr key={paciente.id}>
                            <td> {paciente.patient.pcteNom} {paciente.patient.pcteNom2} {paciente.patient.pcteApe1} {paciente.patient.pcteApe2}</td>
                            <td> {paciente.formattedHour}</td>
                            <td>{paciente.category.categoryNm}</td>
                            <td>
                              <div className="rounded text-center py-2">
                                <button
                                  style={{ width: "12rem", height: "3rem" }}
                                  type="button"
                                  className={`text-center pr-1 m-0 border py-2 rounded fw-normal  ${
                                    paciente.estatus === "Completada"
                                      ? "bg-primary text-light"
                                      : paciente.estatus === "Cancelada"
                                      ? "bg-danger text-light"
                                      : "button-send bg-success text-white"
                                  }`}
                                  title={
                                    paciente.estatus === "Completada"
                                      ? "Cita Completada"
                                      : paciente.estatus === "Cancelada"
                                      ? "Cita Cancelada"
                                      : "Enviar a Consulta"
                                  }
                                  disabled={
                                    paciente.estatus === "Cancelada" || paciente.estatus === "Completada"
                                  }
                                  onClick={() => handleEnviarConsultaClick(paciente)}
                                >
                                  {paciente.estatus === "Completada" ? (
                                    <i className="bi bi-check-circle-fill text-opacity pe-2"></i>
                                  ) : paciente.estatus === "Cancelada" ? (
                                    <i className="bi bi-x-circle-fill text-opacity pe-2"></i>
                                  ) : (
                                    <i className="bi bi-arrow-right-circle-fill text-opacity pe-2"></i>
                                  )}
                                  {paciente.estatus === "Completada"
                                    ? "Cita Completada"
                                    : paciente.estatus === "Cancelada"
                                    ? "Cita Cancelada"
                                    : "Enviar a Consulta"}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                       {/* Controles de Paginación */}
                       {!showForm && (
                         <div className="d-flex justify-content-end">
                         <nav aria-label="Page navigation example">
                           <ul className="pagination justify-content-center">
                             <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                               <button
                                 className="page-link"
                                 onClick={() => handlePageChange(currentPage - 1)}
                               >
                                 &laquo;
                               </button>
                             </li>
                             {Array.from({ length: totalPages }, (_, index) => (
                               <li
                                 key={index}
                                 className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                               >
                                 <button
                                   className="page-link"
                                   onClick={() => handlePageChange(index + 1)}
                                 >
                                   {index + 1}
                                 </button>
                               </li>
                             ))}
                             <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                               <button
                                 className="page-link"
                                 onClick={() => handlePageChange(currentPage + 1)}
                               >
                                 &raquo;
                               </button>
                             </li>
                           </ul>
                         </nav>
                       </div>
                        )}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handlePrintPDF}>
                    Imprimir
                  </Button>
                  <Button color="secondary" onClick={handleCloseCitasModal}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </Modal>

            {/* Citas 2*/}

            <div
              className="bg-white rounded-2"
              style={{
                position: "relative",
                top: "-6rem",
                height: "30rem",
                width: "60%",
              }}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  height: "30rem",
                }}
              >
                {!chartData || Object.keys(chartData).length === 0 ? (
                  <SkeletonLoader width="100%" height="30rem" />
                ) : (
                  <Bar data={chartData} options={chartOptions} />
                )}
              </div>
            </div>

            {/* Chard 1*/}
          </div>
          {/* Citas & Chards */}

          <div className="bg-white rounded-2" style={{ height: "38rem", margin: "0 2rem" }}>
            <div style={{ textAlign: "left" }}>
              <label className="fs-5 py-3 px-3 text-uppercase fw-bold">Listado de Ultimas Consultas</label>
            </div>
            <div className="text-start px-1">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre del Paciente</th>
                    <th>Dirección</th>
                    <th>Fecha de Consulta</th>
                    <th>Motivo de Consulta</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    // Mostrar 5 filas de skeleton mientras carga
                    Array.from({ length: 13 }).map((_, index) => <SkeletonTableRow key={index} />)
                  ) : (
                    displayedConsults.map((consulta) => (
                      <tr key={consulta.consultId}>
                        <td style={{ width: "5%" }}>{consulta.consultId}</td>
                        <td style={{ width: "25%", textAlign: "left" }}>
                          {consulta.pcteNom || ""} {consulta.pcteNom2 || ""} {consulta.pcteApe1 || ""} {consulta.pcteApe2 || ""}
                        </td>
                        <td style={{ width: "25%", textAlign: "left" }} data-bs-toggle="tooltip" data-bs-placement="top" title={consulta.pcteDir}>
                          {consulta.pcteDir?.length > 40 ? `${consulta.pcteDir.slice(0, 25)}...` : consulta.pcteDir}
                        </td>
                        <td style={{ width: "20%" }}>{consulta.consultFec}</td>
                        <td style={{ width: "30%", textAlign: "left" }} data-bs-toggle="tooltip" data-bs-placement="top" title={consulta.consultMot}>
                          {consulta.consultMot?.length > 30 ? `${consulta.consultMot.slice(0, 35)}...` : consulta.consultMot}
                        </td>
                        <td style={{ width: "5%" }}>
                          <div className="text-center">
                            <button
                              type="button"
                              className="p-1 border-0 bg-opacity"
                              title="Ver detalle de la consulta"
                              id="hoverIcon"
                              onClick={() => handleViewDetail(consulta)}
                            >
                              <i className="bi bi-folder2-open"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Lista de ultimas Consultas */}
           {/* Modal para mostrar detalles de la consulta */}
          <Modal isOpen={modalOpen} toggle={toggleModal} 
            size="lg"
            style={{ maxWidth: "60rem" }}>
            <ModalHeader toggle={toggleModal}>Detalle Resumido de la Consulta</ModalHeader>
            <ModalBody>
              {selectedConsulta && (
                <div>
                   <div className="d-flex py-2 m-1 border rounded bg-primary text-white">
                    <div
                      className="d-flex align-items-center px-2"
                      style={{
                        width: "7rem",
                      }}
                    >
                      <img
                        alt="Sample"
                        src="https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png"
                        style={{
                          width: "5rem",
                          // marginTop:'1rem'
                        }}
                      />
                    </div>
                    {/* Imagen */}
                    <div>
                      <div>
                        <label className="mx-1">
                          No. Expediente:
                        </label>
                        <span className="fw-bold">
                          {selectedConsulta.consultId}
                        </span>
                      </div>
                      {/* Expediente */}
                      <div>
                        <label className="mx-1">
                          Fecha del Antecedente:
                        </label>
                        <span className="fw-bold">
                          {selectedConsulta.consultFec}
                        </span>
                      </div>
                      {/* Fecha */}
                      <div className="input-group input-group-sm d-flex align-items-center">
                        <label className="mx-1">
                          Paciente:{" "}
                        </label>
                        <label
                          className="border-0 fw-bold"
                          id="pcteNom"
                        >
                         {`${selectedConsulta.pcteNom || ""} ${selectedConsulta.pcteNom2 || ""} ${selectedConsulta.pcteApe1 || ""} ${selectedConsulta.pcteApe2 || ""}`}
                        </label>
                      </div>
                      <div className="input-group input-group-sm d-flex align-items-center">
                        <label className="mx-1">
                          Dirección:{" "}
                        </label>
                        <label
                          className="border-0 fw-bold"
                          id="pcteNom"
                        >
                         {`${selectedConsulta.pcteDir}`}
                        </label>
                      </div>
                      {/* Paciente */}
                    </div>
                  </div>
                  {/* Información del paciente y consulta */}

                  <div className="d-flex pt-3">
                    {/* Contenido del Nav */}
                    <div className="pe-1 d-flex flex-column" style={{ flex: 1 }}>
                      <p className="d-flex flex-column pb-4 border-bottom fs-4"><strong className="text-decoration-underline pb-2">Motivo de Consulta:</strong> {selectedConsulta.consultMot}</p>
                      <p><strong>Observación:</strong> {selectedConsulta.consultHea || "SIN OBSERVACIÓN"}</p>
                      <p><strong>Tipo de Diagnóstico:</strong> {selectedConsulta.consultDiag || "SIN TIPO DE DIAGNÓSTICO"}</p>
                      <p><strong>Clasificación de Diagnóstico:</strong> {selectedConsulta.consultClaDiag || "SIN CLASIFICACIÓN DE DIAGNÓSTICO"}</p>
                      <p><strong>Cie-10:</strong> {selectedConsulta.codeCie10 || "SIN ENFERMEDAD"}</p>
                      <p><strong>Estudio de Gabinete:</strong> {selectedConsulta.estGabinete || "SIN ESTUDIO DE GABINETE"}</p>
                      <p><strong>Resultado de Laboratorio:</strong> {selectedConsulta.resultLab || "SIN RESULTADO DE LAB."}</p>
                    </div>
                    {/* Contenido del TabContent */}
                  </div>
                </div>
              )}
              
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>

        </div>
        {currentForm === "consultaForm" && selected && paciente && formulario && (
          <div className="main-content">
            <ConsultaForm
              onCloseForm={handleCloseForm}
              paciente={paciente}
              formulario={formulario}
              setCurrentForm={setCurrentForm}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainContent;
